import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Modal,
    Stack,
    TextareaAutosize,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
  import FullscreenIcon from '@mui/icons-material/Fullscreen';
  import { useDispatch } from "react-redux";
  import { showToast } from "../../toast/toast";
  import { paymentVerifyApi } from "../../store/orders/updateOrderStatus";
  import { selectOutletApi } from "../../store/viewOrder/selectOutlet";
  import { handleDownload } from "./downloadFile";
  
  const AcceptOrRejectRefundModal = ({ open, handleClose, mainOrderId, orderDetails, selectedCluster }) => {
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [loadingReject, setLoadingReject] = useState(false);
    const handleChange = (event) => {
      setFeedback(event.target.value);
    };
  
    const sendFeedbackSubmit = async (isAccepted) => {
        
      if (feedback === "") {
        showToast(`Please enter a comment`, 2);
        return;
      }
      const dataTobeSent = {
        'order_id': mainOrderId,
        'refund_id': orderDetails?.id,
        'refund_payment_accepted_by_dealer': isAccepted,
        'comment': feedback
      }
      if(isAccepted===true){
          setLoadingAccept(true);
          setLoadingReject(false);
      }
      if(isAccepted===false){
          setLoadingReject(true);
          setLoadingAccept(false);
      }
      await dispatch(paymentVerifyApi(dataTobeSent))
        .then(() => {
            setLoadingReject(false);
            setLoadingAccept(false);
            handleCloseModal();

          setTimeout(() => {
            dispatch(selectOutletApi({ outlet_id: selectedCluster }))
          }, 1000);
          setFeedback("");
         
        })
        .catch((error) => {
            setLoadingReject(false);
            setLoadingAccept(false);
          console.error("Error sending feedback:", error);
        });
    };
  
    const handleCloseModal = () => {
      setFeedback("");
      handleClose();
    };
  
    return (
      <Modal
        open={open}
        onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card sx={{ width: 500, p: 3, borderRadius: 2, boxShadow: 3, position: 'relative' }}>
          <CardHeader
            title="Refund Details"
            sx={{ textAlign: "center", p: 1,fontWeight:600 }}
            
          />
          <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
          <IconButton onClick={handleCloseModal} sx={{ position: 'absolute', top: 10, right: 10 }}>
                <HighlightOffIcon />
              </IconButton>
        </Stack>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ textAlign: 'center', mb: "30px" }}>
            <Typography variant="body1" sx={{fontSize:"17px",}}>
              Please find the refund amount details and proof
            </Typography>
          </Box>
          <Box className="d-flex gap-5 justify-content-between" sx={{ mb: 2 }}>
            <Box>
            <Typography variant="body2" fontWeight={"600 !important"} sx={{mb:"5px"}}>Amount Paid</Typography>{orderDetails?.amount_paid}
            </Box>
            <Box>
            <Typography variant="body2" fontWeight={"600 !important"} sx={{mb:"5px"}}>UTR Number</Typography>{orderDetails?.UTR_detail}
            </Box>
            <Box>
            <Typography variant="body2" fontWeight={"600 !important"}>Receipt</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap:"5px" }}>
              
              <DownloadForOfflineRoundedIcon className="cursor-pointer"
              onClick={() =>
                                      handleDownload(
                                        new Date() + "Refund_Payment_Receipt",
                                        orderDetails?.upload_receipt
                                      )
                                    }
             // onClick={() => handleDownload(`Refund_Payment_Receipt`, orderDetails?.upload_receipt)}
              />
              <FullscreenIcon className="cursor-pointer" 
              
              onClick={() => window.open(orderDetails?.upload_receipt)}
              />
            </Box>
          </Box>
          </Box>
          
          
          
          <CardContent sx={{ mb: 2,px: 0 }}>
          <Typography variant="body2" sx={{ fontSize:"16px"}}>
            Please add comment
          </Typography>
            <TextareaAutosize
              minRows={4}
              placeholder="Enter your comment"
              value={feedback}
              onChange={handleChange}
              style={{
                width: "100%",
                border: "1px solid #C4D3DF",
                borderRadius: "8px",
                padding: "10px",
                fontFamily: "inherit",
                fontSize: "15px",
                boxSizing:"border-box"
              }}
            />
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => sendFeedbackSubmit(false)}
              sx={{ borderRadius: 1, textTransform: 'none', px: 3 }}
              disabled={loadingAccept || loadingReject}
            >
             {loadingReject ? "Sending..." : "Reject"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => sendFeedbackSubmit(true)}
              sx={{ borderRadius: 1, textTransform: 'none', px: 3 }}
              disabled={loadingAccept || loadingReject}
            >
              {loadingAccept ? "Sending..." : "Accept"}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    );
  };
  
  export default AcceptOrRejectRefundModal;
  