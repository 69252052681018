import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getRevisedSchematic } from '../../store/layout/revisedSchematic';
import { convertToFormData } from '../ConvertToFormData';
import { postRevisedLayoutView } from '../../store/layout/postRevisedLayoutView';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import otherFile from '../../assets/img/file-icon.png'

import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from "../reusable/downloadFile";
import { showToast } from '../../toast/toast';
import CancelIcon from "@mui/icons-material/Cancel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, Date, PaymentStatus, Invoice, Action) {
    return { name, Date, PaymentStatus, Invoice, Action };
}

export default function RevisedLayout({ setSelectedTab }) {
    localStorage.removeItem("isRevisedViewClick");
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = React.useState();
    const [openPopper, setOpenPopper] = useState(null);

    const handleClickPopper = (newPlacement, index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopper((prev) => (prev === index ? null : index)); // Toggle popper for this row
        setPlacement(newPlacement);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenPopper(null);
        setAnchorEl(null);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const designEstimate = async () => {
        const formD = {
            'form_id': formId.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getRevisedSchematic(data));
            setData(response?.payload?.data);
        } catch (error) {
        }
    }

    useEffect(() => {
        designEstimate();
    }, []);

    const handleView = async (data) => {
        try {
            localStorage.setItem("isRevisedViewClick", 1);
            await dispatch(postRevisedLayoutView(data));
            setSelectedTab(4)
        } catch (error) {
            showToast(error, 2)
        }
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Schematic Revisions</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                        <Button className='layout-blue-btn'>
                            Schematic by KIA
                        </Button>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Schematic Send Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {/* <TableBody>
                                {data?.map((row, index) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row" sx={{paddingBlock: "5px !important"}}>
                                            {row?.design_estimate?.estimate_type}
                                        </StyledTableCell>
                                        <StyledTableCell  align="center" sx={{paddingBlock: "5px !important"}}>{row.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{paddingBlock: "5px !important"}}>
                                            <Button className="DownloadBtn" aria-describedby={id} onClick={handleClickPopper('right', index)} sx={{marginRight: "15px" }}>Download</Button>
                                    
                                            <Popper
                                                id={`popper-${index}`}
                                                open={openPopper === index}
                                                anchorEl={anchorEl}
                                                transition
                                                placement={placement}
                                            //  id={id} open={open} anchorEl={anchorEl} transition placement={placement}
                                             >
                                           
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                position: 'relative',
                                                                width: 320,
                                                                minHeight:150,
                                                              
                                                             
                                                               
                                                            }}
                                                        >
                                                            <CancelIcon sx={{position: "absolute", right: "5px",top: "5px",cursor:"pointer" }}  onClick={handleClose} />
                                                            {row?.schematic_pdf?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={pdf} className="my-auto" alt="PDF" style={{marginRight:"3px"}}/>
                                                                            <Typography>PDF File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                            {row?.schematic_cad?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files} >
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={cad} className="my-auto" alt="CAD" style={{marginRight:"3px"}}/>
                                                                            <Typography>CAD File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}

                                                            {row?.schematic_files?.map((schematicLayoutData) => (
                                                                <Box key={schematicLayoutData.files}>
                                                                    <Box className="m-auto p-relative">
                                                                        <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                            <img width={30} height={30} src={otherFile} className="my-auto" alt="Other File" style={{marginRight:"3px"}}/>
                                                                            <Typography>Other File</Typography>
                                                                            <DownloadForOfflineRoundedIcon
                                                                                className="my-auto"
                                                                                onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)}
                                                                            />
                                                                            <FullscreenIcon
                                                                                className="my-auto"
                                                                                onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)}
                                                                            />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>


                                            <Button className="DownloadBtn" onClick={() => handleView(row)}>View</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody> */}


                            <TableBody>
                                {data?.length > 0 ? (
                                    data?.filter((row) => row.approve_by_admin === true).length > 0 ? (
                                        data
                                            .filter((row) => row.approve_by_admin === true)
                                            .map((row, index) => (
                                                <StyledTableRow key={row.name}>
                                                    <StyledTableCell component="th" scope="row" sx={{ paddingBlock: "5px !important" }}>

                                                        {row?.schematic_type}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ paddingBlock: "5px !important" }}>
                                                        {row.created_at.split("T")[0]}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" sx={{ paddingBlock: "5px !important" }}>
                                                        <Button
                                                            className="DownloadBtn"
                                                            aria-describedby={id}
                                                            onClick={handleClickPopper('right', index)}
                                                            sx={{ marginRight: "15px" }}
                                                        >
                                                            Download
                                                        </Button>

                                                        <Popper
                                                            id={`popper-${index}`}
                                                            open={openPopper === index}
                                                            anchorEl={anchorEl}
                                                            transition
                                                            placement={placement}
                                                        >
                                                            {({ TransitionProps }) => (
                                                                <Fade {...TransitionProps} timeout={350}>
                                                                    <Box
                                                                        sx={{
                                                                            border: '1px solid',
                                                                            borderColor: 'grey.300',
                                                                            boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                            p: 1,
                                                                            bgcolor: 'background.paper',
                                                                            borderRadius: 0,
                                                                            position: 'relative',
                                                                            width: 320,
                                                                            minHeight: 150,
                                                                        }}
                                                                    >
                                                                        <CancelIcon
                                                                            sx={{ position: "absolute", right: "5px", top: "5px", cursor: "pointer" }}
                                                                            onClick={handleClose}
                                                                        />
                                                                        {row?.schematic_pdf?.map((schematicLayoutData) => (
                                                                            <Box key={schematicLayoutData.files}>
                                                                                <Box className="m-auto p-relative">
                                                                                    <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            src={pdf}
                                                                                            className="my-auto"
                                                                                            alt="PDF"
                                                                                            style={{ marginRight: "3px" }}
                                                                                        />
                                                                                        <Typography>PDF File</Typography>
                                                                                        <DownloadForOfflineRoundedIcon
                                                                                            className="my-auto"
                                                                                            onClick={() =>
                                                                                                handleDownload(new Date() + 'schematic_pdf', schematicLayoutData.files)
                                                                                            }
                                                                                        />
                                                                                        <FullscreenIcon
                                                                                            className="my-auto"
                                                                                            onClick={() =>
                                                                                                window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                        {row?.schematic_cad?.map((schematicLayoutData) => (
                                                                            <Box key={schematicLayoutData.files}>
                                                                                <Box className="m-auto p-relative">
                                                                                    <Tooltip title={schematicLayoutData.files} placement="top" className="uploadedfilename">
                                                                                        <img
                                                                                            width={30}
                                                                                            height={30}
                                                                                            src={cad}
                                                                                            className="my-auto"
                                                                                            alt="CAD"
                                                                                            style={{ marginRight: "3px" }}
                                                                                        />
                                                                                        <Typography>CAD File</Typography>
                                                                                        <DownloadForOfflineRoundedIcon
                                                                                            className="my-auto"
                                                                                            onClick={() =>
                                                                                                handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)
                                                                                            }
                                                                                        />
                                                                                        <FullscreenIcon
                                                                                            className="my-auto"
                                                                                            onClick={() =>
                                                                                                window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)
                                                                                            }
                                                                                        />
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                        {/* Additional logic for other files */}
                                                                    </Box>
                                                                </Fade>
                                                            )}
                                                        </Popper>

                                                        <Button className="DownloadBtn" onClick={() => handleView(row)}>
                                                            View
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                    ) : (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={3} align="center">
                                                No data found or it is not approved by admin.
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                ) : (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={3} align="center">
                                            No data found.
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>


                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}