import EmailIcon from '@mui/icons-material/Email';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Avatar, Box, Button, Card, CardActionArea, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ForgetPasswordEmailConf from './ForgetPasswordEmailConf';
import ForgetPasswordSMSConf from './ForgetPasswordSMSConf';
import ModalCss from "../../styles/modal.module.scss";
import { Link } from 'react-router-dom';
import { forgetPasswordViaEmailApi } from '../../store/auth/forgetPasswordViaEmail';
import { authOtpViaEmailApi } from '../../store/auth/authOtpViaEmail';
import { useDispatch, useSelector } from 'react-redux';
import { authOtpViaSMSApi } from '../../store/auth/authOtpViaSMS';
import ResetViaEmailOTP from './ResetViaEmailOTP';
import { showToast } from '../../toast/toast';
export default function ForgetPassword({ open, setOpen, data }) {

  const forgetPasswordState = useSelector(state => state.forgetPasswordViaEmail);
  const [emailInputConf, setEmailInputConf] = useState(false)
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false)
  const [emailVerifyModal, setEmailVerifyModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const forgotpassword = "forget_password"
  // const handleClose = () => setOpen(false)
  const dispatch = useDispatch();
  const loginState = useSelector(state => state.login);
  const user_type = "Dealor";
  const email = localStorage.getItem("email")

  const handleSentOTPViaEmail = () => {
    setSelectedOption('email');
  }

 
  const handleClose = () => {
    setSelectedOption(null);
    setOpen(false);
  } 

  // const handleSentOTPViaSMS = () => {
  //   setSelectedOption('sms');
  // }

  const handleSendLink = async () => {
    if (selectedOption === 'email') {
      const responseForgetPassword = await dispatch(forgetPasswordViaEmailApi({ type: "email", user_type, email: data?.email }));
      if (!responseForgetPassword?.payload?.error) {
        localStorage.setItem('access_token', responseForgetPassword?.payload?.result?.access_token)
        setEmailVerifyModal(true);
        dispatch(authOtpViaEmailApi({ type: "email", user_type, email: data?.email, forget_password: "forget_password" }));
        setOpen(false);
        // setSelectedOption(null);
      } else {
        showToast(responseForgetPassword?.payload?.message, 2)
      }


    } 
    // else if (selectedOption === 'sms') {
    //   dispatch(authOtpViaSMSApi());
    //   setPhoneVerifyModal(true);
    //   setSelectedOption(null);
    // }


    // if (selectedOption === 'email') {
    //     setEmailVerifyModal(true);

    // }
    // if (selectedOption === 'sms') {
    //     setPhoneVerifyModal(true);
    // }

  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >Forget Password</Typography>
              <Typography className={ModalCss?.subtitle} fontSize={16}>Please select an option to send OTP to reset password</Typography>
            </Stack>
            <Card
              sx={{
                p: 0,
                marginTop: "30px !important",
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}>
              <CardActionArea
                // sx={{ px: 2, py: 3 }} 
                style={{ padding: "16px", backgroundColor: selectedOption === 'email' ? '#A8A8A8' : '#fff' }}
                onClick={handleSentOTPViaEmail}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                    <svg
                      width="23"
                      height="17"
                      viewBox="0 0 23 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 2.09231C23 1.53739 22.7774 1.00521 22.3813 0.612823C21.9851 0.220439 21.4478 0 20.8875 0H2.11251C1.55224 0 1.01491 0.220439 0.618741 0.612823C0.222568 1.00521 0 1.53739 0 2.09231V14.9077C0 15.4626 0.222568 15.9948 0.618741 16.3872C1.01491 16.7796 1.55224 17 2.11251 17H20.8875C21.4478 17 21.9851 16.7796 22.3813 16.3872C22.7774 15.9948 23 15.4626 23 14.9077V2.09231ZM2.11251 1.04615H20.8875C20.9433 1.04702 20.9989 1.05227 21.0538 1.06185L12.2631 10.1608C12.1646 10.2624 12.0463 10.3433 11.9154 10.3986C11.7845 10.4538 11.6436 10.4823 11.5013 10.4823C11.359 10.4823 11.2182 10.4538 11.0873 10.3986C10.9564 10.3433 10.8381 10.2624 10.7395 10.1608L1.94615 1.06185C2.0011 1.05227 2.05673 1.04702 2.11251 1.04615ZM1.05626 2.09231C1.05769 1.96701 1.08184 1.84299 1.12755 1.72615L6.6729 7.46692L1.05626 14.3035V2.09231ZM20.8875 15.9538H2.11251C1.96768 15.9534 1.8245 15.9234 1.69185 15.8658C1.5592 15.8082 1.43993 15.7243 1.34145 15.6191L7.40964 8.23585L9.97635 10.8931C10.1735 11.0968 10.4103 11.2589 10.6723 11.3696C10.9344 11.4804 11.2164 11.5375 11.5013 11.5375C11.7862 11.5375 12.0682 11.4804 12.3303 11.3696C12.5924 11.2589 12.8291 11.0968 13.0263 10.8931L15.424 8.41108L21.6559 15.6269C21.5566 15.7294 21.4372 15.8108 21.3051 15.8661C21.173 15.9214 21.0309 15.9495 20.8875 15.9486V15.9538ZM21.9437 14.3506L16.1555 7.64477L21.8724 1.72615C21.9182 1.84299 21.9423 1.96701 21.9437 2.09231V14.3506Z"
                        fill="#05141F"
                      />
                    </svg>
                  </Avatar>
                  <Box>
                    <Typography fontWeight={600} fontSize={18} textTransform={'none'}>Reset via Email</Typography>
                    <Typography fontSize={15} textTransform={'none'}>A reset OTP will be sent to your registered email address</Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card>
            {/* <Card
              sx={{
                p: 0,
                border: "1px solid rgba(171, 200, 221, 1)",
                boxShadow: "none",
              }}>
              <CardActionArea sx={{ px: 2, py: 3 }} onClick={handleSentOTPViaSMS}>
                <Stack direction='row' alignItems='center' spacing={3}>
                  <Avatar sx={{ background: "rgba(235, 243, 248, 1)", p: 1 }}>
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.7303 22C16.3918 22 16.0506 21.9537 15.7153 21.8573C12.1861 20.8429 8.81567 18.7301 5.96849 15.7473C3.12131 12.7646 1.10448 9.23375 0.136311 5.5365C-0.313751 3.81795 0.377269 1.95015 1.81662 0.994453C2.16138 0.765403 2.51967 0.541576 2.88165 0.329098C3.62175 -0.105846 4.53184 -0.109818 5.25678 0.318088C6.96992 1.32872 8.1461 2.90369 8.75262 4.99918C8.98577 5.8046 8.82033 6.67585 8.31025 7.32974L7.34631 8.56556C6.96439 9.05521 6.93081 9.76063 7.26451 10.2808C8.31035 11.9115 9.62999 13.294 11.1866 14.3896C11.6833 14.7393 12.3567 14.7039 12.8239 14.3039L14.0036 13.2941C14.6276 12.7596 15.4591 12.5861 16.2282 12.8307C18.2284 13.466 19.7318 14.6982 20.6966 16.4931C21.1049 17.2523 21.1008 18.2057 20.6861 18.9812C20.4832 19.3604 20.2695 19.7357 20.0509 20.097C19.3251 21.2966 18.0481 22 16.7303 22ZM4.08579 1.08208C3.84624 1.08208 3.60442 1.14609 3.38697 1.27378C3.04135 1.47673 2.6992 1.69045 2.37016 1.90906C1.31087 2.61232 0.802196 3.98616 1.13319 5.25002C2.05401 8.76634 3.97885 12.1315 6.69938 14.9817C9.42002 17.8317 12.6322 19.8482 15.9887 20.813C17.1952 21.1589 18.5064 20.6267 19.1778 19.5171C19.3865 19.1723 19.5905 18.8139 19.7844 18.4518C20.0239 18.0038 20.0283 17.4568 19.7959 17.0246C18.9544 15.4593 17.6894 14.4264 15.9283 13.8669C15.4895 13.7276 15.0145 13.8269 14.6577 14.1327L13.4778 15.1425C12.6608 15.8421 11.4816 15.9022 10.61 15.2886C8.94168 14.1142 7.52746 12.6326 6.40642 10.8849C5.82093 9.97186 5.87824 8.73649 6.54586 7.88045L7.5098 6.64452C7.80157 6.27041 7.89637 5.77282 7.76343 5.31336C7.2294 3.46836 6.24347 2.1431 4.7494 1.26164C4.54658 1.14189 4.31721 1.08208 4.08579 1.08208Z"
                        fill="#05141F"
                      />
                    </svg>
                  </Avatar>
                  <Box>
                    <Typography fontWeight={600} fontSize={18}>Reset via SMS</Typography>
                    <Typography fontSize={15}>A reset code will be send to your registered phone number</Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card> */}
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                //handleSendLink
                onClick={() => { handleSendLink(); handleClose(); }}
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "16px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                }} >Send OTP</Button>
            </Box>

            {/* <Stack justifyContent="center" direction="row" alignItems="center" fontSize={'18px'} sx={{ marginTop: "30px !important " }}>
                <Typography
                textAlign={"center"}
                fontSize={'inherit'}
                
                color={"#000000"}
                >
                Didn’t receive an OTP?
                </Typography>
                <Button 
                    marginLeft="5px !important"
                    onClick={handleSentOTPViaEmail}
                    sx={{fontSize: "16px", fontWeight: "600"}}
                >
                    Resend
                </Button>
            </Stack> */}
          </Stack>
        </Card>
      </Modal>

      {/* <ForgetPasswordEmailConf open={emailInputConf} setOpen={setEmailInputConf} />*/}
      {/* <ForgetPasswordSMSConf open={phoneVerifyModal} setOpen={setPhoneVerifyModal} /> 
       <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} /> */}
      <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} openPreviousModal={setOpen}  handleCloseP={handleClose} handleSendLink={handleSendLink}/>
    </>
  )
}
