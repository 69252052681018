import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyAuthOtpApi } from "../../store/auth/verifyAuthOtp";
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import BackdropUI from "../../ui/Backdrop";
import DealerStatusConf from "./DealerStatusConf";
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from "../../toast/toast";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
export default function OTPEmailAuth({ open, setOpen, handleSentOTPViaEmail, openPreviousModal, handleCloseP, handleSendLink }) {
  const authOtpViaEmailSelector = useSelector(
    (state) => state?.authOtpViaEmail
  );
  const { loading } = authOtpViaEmailSelector;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [backdrop, setBackDrop] = useState(false);
  const [dealorStatus, setDealorStatus] = useState(false);
  const loginState = useSelector((state) => state.login);
  const user_type = "Dealor";
  const dealorEmail = localStorage.getItem("email");
  // const handleClose = () => setOpen(false);

  const handleClose = () => {
    setOtp("");
    handleCloseP()
    setOpen(false);
  } 

  let maskedEmail = "";
  if (dealorEmail) {
    const [localPart, domain] = dealorEmail.split('@');
    maskedEmail = `${localPart.slice(0, 3)}*****@${domain}`;
  }
 

  const handleVerify = async () => {
    const verifyAuthOTP = await dispatch(
      verifyAuthOtpApi({ otp: Number(otp), user_type, type: "email", email: dealorEmail })
    );
    if (!verifyAuthOTP?.payload?.error) {
      localStorage.setItem(
        "access_token",
        verifyAuthOTP?.payload?.result?.access_token
      );

      navigate("/")
  
    }
    else{
      showToast(verifyAuthOTP.payload.message,2)
    }
  };

  // const handleResendOtp = () => {
  //   dispatch(authOtpViaEmailApi());
  // };

  useEffect(() => {
    if (loading) setBackDrop(true);
    else setBackDrop(false);
  }, [backdrop, loading]);

  function handleBack(){
    setOtp("");
    openPreviousModal(true)
    handleClose()
  }

    const handleResendOtp = () => {
    setOtp("");
    handleSendLink()
  };

  // const handlePaste = (event) => {
  //   console.log("event", event);
  //   event.preventDefault(); // Prevent the default paste action
  //   const data = event.clipboardData.getData('text');
  //   console.log(data, "Pasted data");
    
  //   // Filter and set only numeric values
  //   const filteredData = data.replace(/\D/g, ''); // Remove non-digit characters
  //   setOtp((prev) => (prev + filteredData).slice(0, 4)); // Limit to 4 digits
  // };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            {/* <Stack 
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack> */}
              <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              position={"absolute"}
              left={5} // Aligns the back button to the left
              right={5} // Aligns the close button to the right
              top={5}
              width="100%"
            >
              <IconButton>
                <ArrowBackIosIcon onClick={handleBack}/>
              </IconButton>
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
          </Stack>
            <Stack alignItems="center">
              <Typography
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >
                Verify OTP
              </Typography>
              <Typography
                className={ModalCss?.subtitle}
                 
              >{`We have sent an OTP to your registered email address `}</Typography>
              <Typography className={ModalCss?.subtitle}> {maskedEmail}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px",
              }}
              inputStyle={{
                width: 72,
                height: 72,
                fontSize: "20px",
                border: "2px solid black",
                borderRadius: "10px",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              )}
              isInputNum={true}
            />
              {/* <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px",
              }}
              inputStyle={{
                width: 72,
                height: 72,
                fontSize: "20px",
                border: "2px solid black",
                borderRadius: "10px",
              }}
              value={otp}
              onChange={setOtp}
              onPaste={handlePaste}
         

              inputType="number"
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              )}
              isInputNum={true}
            /> */}


            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={handleVerify}
                disabled={otp?.length < 4}
              >
                Verify Account
              </Button>
            </Box>
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={2} fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'} >Didn't receive the OTP?
              </Typography>
              {/* <Button onClick={() => handleResendOtp()}>Resend</Button> */}
              <Button sx={{fontSize: "16px", fontWeight: "600"}} onClick={() => handleResendOtp()} className="black-btn">Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
      <DealerStatusConf open={dealorStatus} setOpen={setDealorStatus} />
      <BackdropUI open={backdrop} />
    </>
  );
}
