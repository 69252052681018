import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  TextareaAutosize,
  Typography,
  Input,
} from "@mui/material";
import React, { useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
import { paymentWithUtrApi } from "../../store/layout/paymentWithUtr";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../toast/toast";
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const utrSchema = yup.string().required("UTR number is required")
  .matches(/^[a-zA-Z0-9]{16,22}$/, "UTR must be alphanumeric and 16-22 characters long")


const Payment = ({ open, handleClose, schematicData, closeParentModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);
  const [utrNo, setUtrNo] = useState("");
  const [fileState, setFileState] = useState(null);
  const estimateId = useSelector((state) => state?.designEstimateReducer?.result?.data);
  const handlePaymentInput = () => {
    inputRef.current.click();

  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileState(file)
  };

  const validateUtr = async () => {
    try {
      await utrSchema.validate(utrNo);
      return true;
    } catch (error) {
      showToast(error.message, 2);
      return false;
    }
  };

  const submitPaymentDetails = async () => {
    const isUtrValid = await validateUtr(); 
    if (!isUtrValid) return;
    if (!fileState) {
      showToast("Please upload the payment proof", 2);
      return;
    }
    const formData = new FormData();
    formData.append('estimate_id', schematicData?.design_estimate?.id);
    formData.append('utr_no', utrNo);
    formData.append("payment_recipt", fileState);
    formData.append("schematic_id", schematicData?.id);
    try {
      const data = await dispatch(paymentWithUtrApi(formData));
      showToast("Schematic Layout approved & Payment Details updated successfully", 1)
      handleClose();
      closeParentModal();
      setUtrNo(""); 
      setFileState(null);

    } catch (error) {
      console.error("Error editing email template:", error);
    }
  };
 const handleModalClose = () => {
   handleClose();
   closeParentModal();
   setUtrNo(""); 
      setFileState(null);
 };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <CardHeader
            className="popup-header-rev"
            action={
              <IconButton onClick={handleModalClose}>
                <HighlightOffIcon />
              </IconButton>
            }
            title="Payment Details"
            subheader="Please update payment details"
          />
          <CardContent
            className="d-flex flex-column"
            sx={{
              maxHeight: "300px",
              width: "100%",
              overflowY: "auto",
              boxSizing: "border-box",
            }}>

            <Typography
              variant="h4"
              className="fs-17 fw-400"
              sx={{ marginTop: "0px" }}
            >
              Please mention UTR* number
            </Typography>
            <Input
              sx={{
                width: "100%",
                minHeight: "50px",
                border: "1px solid #C4D3DF",
                borderRadius: "5px",
                padding: "10px",
                boxSizing: "border-box",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              className="bg-white"
              value={utrNo}
              onChange={(e) => setUtrNo(e.target.value)}
            />
            <Typography className="fs-15 mb-10">
              *UTR - Unique Transaction Reference
            </Typography>
            <Typography className="fs-15 mb-10">
              *The UTR number and payment will be validated
            </Typography>
            <Typography
              variant="h4"
              className="fs-20 fw-600 text-center"
              sx={{ marginTop: "40px" }}
            >
              Upload Payment Proof 
            </Typography>
            <Box sx={{ marginTop: "2px", marginBottom: "13px" }}>
              <Box
                className="upload-box text-center mx-auto "
                sx={{ marginBlock: "16px 10px" }}
              >
                <Box className="m-auto">
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept="application/pdf, image/*"
                  />
                  <Button onClick={handlePaymentInput}>
                    <svg
                      width="27"
                      height="25"
                      viewBox="0 0 27 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.4275 24.8695H4.13291C2.203 24.8695 0.632812 23.2756 0.632812 21.3166V16.9316H2.91365V21.3166C2.91365 21.9993 3.46072 22.5543 4.13291 22.5543H23.4271C24.0996 22.5543 24.6464 21.999 24.6464 21.3166V16.9316H26.9272V21.3166C26.9276 23.2756 25.3574 24.8695 23.4275 24.8695Z"
                        fill="#05141F"
                      />
                      <path
                        d="M20.7314 6.57484L14.5882 0.339012C14.3744 0.122044 14.0841 0 13.7817 0C13.4794 0 13.189 0.122044 12.9753 0.339012L6.83203 6.57484L8.44491 8.21202L12.6413 3.95238V19.4477H14.9221V3.95238L19.1186 8.21202L20.7314 6.57484Z"
                        fill="#05141F"
                      />
                    </svg>
                  </Button>
                  <Typography variant="h6" className="fs-14">
                    {fileState ? `File: ${fileState.name}` : "File"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography className="fs-15 mb-10">
            λ You may upload a valid bank payment slip, screenshots and receipts.{" "}
            </Typography>
            <Typography className="fs-15 mb-10">
            λ The proof should contain the UTR number exactly the one mentioned above.{" "}
            </Typography>
            <Typography className="fs-15 mb-10">
            λ Do not upload edited proofs as same shall be validated.
            </Typography>

          </CardContent>
          <CardActions disableSpacing>
            <Box component={"div"}
              width={"100%"}
              marginBlockStart={0.5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"15px 5px 10px"}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: 1.8,
                  boxShadow: "none",
                  backgroundColor: "#000 !important",
                  p: {
                    lg: "15px 46px",
                    md: "13px 35px",
                    sm: "10px 30px",
                    xs: "10px 30px",
                  },
                }}
                className={classes.buttonsStyle}
                onClick={submitPaymentDetails}
              >
                Submit & notify
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default Payment;
