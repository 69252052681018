import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
import { useDispatch } from "react-redux";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { showToast } from "../../toast/toast";
import { ordersApi, uploadInvoiceApi } from "../../store/orders/orders";
import { uploadPaymentApi } from "../../store/viewOrder/uploadPayment";
import '../../styles/popup.scss';
import { viewOrderHistory } from "../../store/viewOrder/viewOrderHistory";
import { selectOutletApi } from "../../store/viewOrder/selectOutlet";

const useStyles = makeStyles((theme) => ({
    bgLightGray: {
        backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
        borderRadius: "10px",
    },
    p10: {
        padding: "10px",
    },
    p20: {
        padding: "20px",
    },
    buttonsStyle: {
        backgroundColor: "#D7EDFE",
    },
    fullWidth: {
        width: "100%",
    },
}));

const UploadPaymentRecipt = ({ open, handleClose, orderId, handleUpdatePaymentDate }) => {
    const classes = useStyles();
    const [fileError, setFileError] = useState("");
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [fileState, setFileState] = useState();
    const [invoiceAmount, setInvoiceAmount] = useState("");
    const [utrNo, setUtrNo] = useState("");
    const [utrError, setUtrError] = useState("");
    const [amountError, setAmountError] = useState("");

    const handleBulkUpload = () => {
        inputRef.current.click();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileExtension = file.name.split('.').pop().toLowerCase();
            
            const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            const validFileTypes = ['application/pdf', ...validImageTypes];
    
            if (validFileTypes.includes(fileType)) {
                setFileState(file);
                setFileError("");
            } else {
                setFileState(null);
                setFileError("Only PDF and image files (JPEG, PNG) are allowed.");
            }
        }
    };
    

    const sendInvoiceToDealer = async () => {
        if (!fileState) {
            showToast("Please upload payment receipt", 2);
            return;
        }
        if (!invoiceAmount || isNaN(invoiceAmount)) {
            setAmountError("Please enter a valid numeric invoice amount",2);
            return;
        }
        if (!utrNo || isNaN(utrNo)) {
            setUtrError("Please enter a valid UTR number");
            return;
        }

        const formData = new FormData();
        formData.append("order_id", orderId);
        formData.append("upload_receipt", fileState);
        formData.append("amount_paid", invoiceAmount)
        formData.append("UTR_detail", utrNo);

        await dispatch(uploadPaymentApi(formData))
            .then((data) => {
                showToast(data?.payload?.message, 1);
                handleUpdatePaymentDate(new Date().toISOString().split("T")[0]); // Update payment_on date
                closeTheModal()
            })

            .catch((error) => {
                console.error("Error sending invoice:", error);
            });
    };

const closeTheModal = () => {
    setFileError("");
    setFileState(null);
    setInvoiceAmount("");
    setUtrNo("");
    setAmountError("");
    setUtrError("");
    handleClose();
};

const handleAmountChange = (e) => {
    const value = e.target.value;
    // Regular expression to match valid numeric values including decimals
    if (/^\d*\.?\d*$/.test(value) && value.length <= 20) {
        setInvoiceAmount(value);
        setAmountError("");
    } else if (value.length > 20) {
        setAmountError("Invoice amount should not exceed 20 characters");
    } else {
        setAmountError("Invoice amount should be numeric");
    }
};

const handleUtrChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 16) {
        setUtrNo(value);
        setUtrError("");
    } else if (value.length > 16) {
        setUtrError("UTR number should not exceed 14 to 16 digits");
    } else {
        setUtrError("UTR number should only contain digits");
    }
};


    return (
        <Modal open={open} onClose={() => closeTheModal()} className={ModalCss.customModal}>
            <Card className={`modal popup ${ModalCss?.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center" }} title="" >
                </CardHeader>
                <CardContent>
                    <Box>
                        <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                            <IconButton onClick={() => closeTheModal()}>
                                <HighlightOffIcon />
                            </IconButton>
                        </Stack>
                        <Stack
                            justifyContent="center"
                            direction="column"
                            gap={2}
                            alignItems="center"
                            fontSize={"18px"}
                            boxSizing={"border-box"}
                            component={"div"}
                        >
                            <Box
                                component={"div"}
                                className={`${classes.bgLightGray} ${classes.borderRadius10} ${classes.p20} ${classes.fullWidth}`}
                                boxSizing={"border-box"}
                                sx={{
                                    backgroundColor: "#e0e0e0",
                                    height: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    component={"div"}
                                    sx={{
                                        width: "100%",
                                        fontSize: "15px",
                                        color: "black",
                                        lineHeight: 1.2,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "20px",
                                    }}
                                >
                                    {fileState ? (
                                        <>
                                            <TextSnippetIcon sx={{ fontSize: "50px" }} />
                                            {fileState.name}
                                        </>
                                    ) : (
                                        <>
                                            <FileUploadOutlinedIcon sx={{ fontSize: "50px" }} />
                                            Upload Payment Receipt
                                        </>
                                    )}
                                    <input
                                        type="file"
                                        ref={inputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileUpload}
                                    />
                                </Typography>
                            </Box>
                            {fileError && (
                                <Typography
                                    component={"div"}
                                    sx={{ color: "red", fontSize: "14px", marginTop: 1 }}
                                >
                                    {fileError}
                                </Typography>
                            )}
                            <Box
                                component={"div"}
                                width={"100%"}
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                                marginTop={"20px"}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleBulkUpload}
                                    className="white-btn"
                                >
                                    Choose file
                                </Button>
                            </Box>
                            <Divider sx={{ width: "100%" }}></Divider>
                            <Box className="w-100">
                                <Typography className="text-start" variant="h6" sx={{ fontSize: "17px", fontWeight: 400 }}>Invoice Amount</Typography>
                                <TextField
                                    style={{ width: "100%", borderRadius: "5px", marginTop: "10px" }}
                                    className="bg-white"
                                    value={invoiceAmount}
                                    error={!!amountError}
                                    helperText={amountError}
                                    onChange={handleAmountChange}
                                />
                                    <Typography className="text-start" variant="h6" sx={{ fontSize: "17px", fontWeight: 400, marginTop: "10px"  }}>UTR No.</Typography>
                                <TextField
                                    style={{ width: "100%", borderRadius: "5px", marginTop: "10px" }}
                                    className="bg-white"
                                    value={utrNo}
                                    error={!!utrError}
                                    helperText={utrError}
                                    onChange={handleUtrChange}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </CardContent>
                <CardActions className="justify-content-end" sx={{ paddingInline: "25px" }}>

                    <Button onClick={closeTheModal} sx={{ textDecoration: "underline", color: "#000000" }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={sendInvoiceToDealer}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            lineHeight: 1.8,
                            boxShadow: "none",
                            backgroundColor: "#000000",
                        }}
                    >
                        Upload
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

export default UploadPaymentRecipt;
