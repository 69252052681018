import React, { useEffect, useState } from "react";
import Body from "../../components/Body";
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { getMaterial } from "../../store/orders/materialSelection";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkOrderStatus } from "../../store/orders/checkOrderStatus";
import ViewLayoutPlan from "../reusable/ViewLayoutPlan";
export default function MaterialSelection() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const result = useSelector((state) => state?.getMaterialReducer?.result);
    const CartId = useSelector((state) => state?.checkOrderStatusReducer?.result.cart_id);
    const outlet_id = useSelector(state => state?.outletListReducer?.result[0]?.id);
    const setName = useSelector(state => state?.outletListReducer?.result[0]);
    const setCategory = localStorage.getItem("category")
    const clusterId= localStorage.getItem("selectedClusterForOrder")
    const [open, setOpen]= useState(false)
    const location = useLocation();
    const selectedClustorInfo = location.state?.selectedClustorInfo;
     function handleOpen(){
        setOpen(true)
    }

    function handleClose(){
        setOpen(false)
    }

    useEffect(() => {
        dispatch(getMaterial())
    }, [])

    function selectCard(item){
        navigate("/order/product-list",{state:{itemId:item}})
    }

    useEffect(() => {
        dispatch(checkOrderStatus({outlet_id:clusterId}))
    }, [])



    return (
        <>
        <Body>
            <Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="flex-wrap justify-content-between mt-15" sx={{ background: "#D9ECFF", padding: "13px 14px", border: "1px solid #82B2E3", borderRadius: "5px" }}>
                    <Typography variant="h4" className="fs-17 fw-400 my-auto" sx={{ color: "#0A385A" }}>Before adding the product, Please check out the layout planning.</Typography>
                    <Button className="black-btn" onClick={handleOpen}>layout Planning</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h4" className="fs-17 fw-400  mb-15 mt-15" >You are ordering for  {selectedClustorInfo?.trade_name} {selectedClustorInfo?.location} {selectedClustorInfo?.facility_type_name} | {setCategory}
                      
                        </Typography>
                    <Typography variant="h4" className="fs-17 fw-600 mb-10" sx={{ borderLeft: "3px solid #0A5FB4", padding: "0px 6px" }}>Material Selection</Typography>
                            <Box className="d-flex w-100 flex-wrap" sx={{ gap: "13px 14px" }}>
                
                      {result && result.length > 0 ? (
                        result.map((item, index) => (
                                <Card key={index}  sx={{ width: 225, height: 215, padding: "8px", borderRadius: "4px", boxShadow: "0px 3px 6px 0px #AECEE6E5" }} onClick={() => selectCard(item.id)}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ borderRadius: "4px" }}
                                            component="img"
                                            height="140"
                                            image={`${process.env.REACT_APP_BASE_URL}${item?.snap}`}
                                            alt="Material"
                                        />
                                        <CardContent sx={{ padding: "10px" }}>
                                            <Typography className="fs-16 fw-400" variant="h5" component="div">
                                                {item.category}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                        ))
                    ) : (
                        <Typography variant="body1">No materials available.</Typography>
                    )}
                            </Box>       
                </Grid>  
            </Grid>
        </Body>
           <ViewLayoutPlan open={open} handleClose={handleClose} />
           </>
     
    )
}