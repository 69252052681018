import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../toast/toast";
import { useNavigate } from "react-router-dom";
import { getStateForOutletApi } from "../../store/orders/getStateForOutlet";
import { getCitiesForOutletApi } from "../../store/orders/getCitiesForOutlet";
import { getRegionForOutletApi } from "../../store/orders/getRegionForOutlet";
import FormStyle from "../../styles/field.module.scss";
import { postAddressApi } from "../../store/orders/addAddress";
import { billingAddressSchema } from "../validationSchemas";
import '../../styles/popup.scss';
const useStyles = makeStyles((theme) => ({
    bgLightGray: {
        backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
        borderRadius: "10px",
    },
    p10: {
        padding: "10px",
    },
    p20: {
        padding: "20px",
    },
    buttonsStyle: {
        backgroundColor: "#D7EDFE",
    },
    fullWidth: {
        width: "100%",
    },
    marginBottom20: {
        marginBottom: "20px",
    },
    inputGroup: {
        display: "flex",
        gap: "20px",
        marginBottom: "20px",
    },
    imageUpload: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px dashed #ccc",
        padding: "20px",
        borderRadius: "10px",
        cursor: "pointer",
    },
    imageUploadText: {
        marginTop: "10px",
        color: "#888",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));



const BillingAddress = ({ open, handleClose, selectedClustorInfo }) => {
 
    const dispatch = useDispatch();
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const setName = useSelector(state => state?.outletListReducer?.result[0]);
    const [inputValues, setInputValues] = useState({
        name: "",
        city_id: "",
        pincode: "",
        address: "",
        zone_id: "",
        state_id: "",
        phone: ""
    });
    const [isSameAddress, setIsSameAddress] = useState(false);
    const navigate = useNavigate();
    const setCategory = localStorage.getItem("category")

    //Get all regions
    const regionList = useSelector((state) => state?.getRegionForOutletReducer?.result?.regions);
    useEffect(() => {
        const response = dispatch(getRegionForOutletApi())
    }, [dispatch]);

    //get State based on region
    const stateList = useSelector((state) => state?.getStateForOutletReducer?.result?.states);
    useEffect(() => {
        if (inputValues?.zone_id) {
            setInputValues({ ...inputValues, state_id: "",city_id: "" })
            const stateRequest = {
                "region_id": inputValues?.zone_id
            }
            const response = dispatch(getStateForOutletApi(stateRequest))
        }
    }, [inputValues?.zone_id])

    //Get cities based on state
    const cityList = useSelector((state) => state?.getCitiesForOutletReducer?.result?.cities);
    useEffect(() => {
        if (inputValues?.state_id) {
            setInputValues({ ...inputValues,city_id: "" })
            const regionRequest = {
                "state_id": inputValues?.state_id
            }
            const response = dispatch(getCitiesForOutletApi(regionRequest))
        }
    }, [inputValues?.state_id])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        setIsSameAddress(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        billingAddressSchema.validate(inputValues, { abortEarly: false })
            .then(() => {
                addRequestAPI();
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
    };
    const addRequestAPI = async () => {
        const formData = new FormData();
        formData.append("name", inputValues.name);
        formData.append("pincode", inputValues.pincode);
        formData.append("city_id", inputValues.city_id);
        formData.append("state_id", inputValues.state_id);
        formData.append("address", inputValues.address);
        formData.append("zone_id", inputValues.zone_id);
        formData.append("same_as_billing_address", isSameAddress);
        formData.append("phone", inputValues.phone);
        try {
            await dispatch(postAddressApi(formData));
            showToast("Payment added successfully");
            // navigate("/order/material-selection");
            navigate("/order/material-selection", {
                state: { selectedClustorInfo }
            });
            handleClose();
        } catch (error) {
            console.error("Error submitting address details:", error);
        }
    };

    useEffect(() => {
        if (selectedClustorInfo) {
            setInputValues({
                name: selectedClustorInfo?.trade_name || "",
                phone: selectedClustorInfo?.dealer_principle_mobile_number || "",
                address: selectedClustorInfo?.correspondence_address || "",
                city_id: selectedClustorInfo?.city_id || "",
                pincode: selectedClustorInfo?.showroom_pincode || "",
                state_id: selectedClustorInfo?.state_id || "",
                zone_id: selectedClustorInfo?.region_id || ""
            });
        }
    }, [selectedClustorInfo]);

    return (
        <>
            <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
                <Card className={`modal popup ${ModalCss?.customModal_md} ${ModalCss?.billingmodal}`}>
                    <CardHeader
                        className="popup-header-rev pt-0 pb-0"
                        action={
                            <IconButton onClick={handleClose}>
                                <HighlightOffIcon />
                            </IconButton>
                        }
                        title={
                            <Box>
                            </Box>
                        }
                    />
                    <CardContent sx={{ paddingInline:{md:"7rem",xs:"1rem"} }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} className="text-center pt-0">
                                <Typography variant="h4" className="fs-19 fw-400 mb-15">
                                    Please confirm your location
                                </Typography>
                                <Typography variant="h4" className="fs-22 fw-600 mb-10">
                                    {/* Amit KIA - Noida */}
                                    {selectedClustorInfo?.trade_name} - {selectedClustorInfo?.location}
                                 </Typography>
                                <Typography variant="h4" className="fs-22 fw-400">
                                    {selectedClustorInfo?.facility_type_name} | {setCategory}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" className="fs-17 fw-600 mb-15px">Billing Address</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" className="fs-16 fw-400">Trade Name</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    value={inputValues.name}
                                    onChange={handleInputChange}
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    className="popup-input"
                                />
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1" className="fs-16 fw-400">Zone</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={inputValues.zone_id}
                                        onChange={handleInputChange}
                                        name="zone_id"
                                        sx={{ marginTop: "10px" }}
                                        className="popup-select"

                                    >
                                        <MenuItem value="">Select Region</MenuItem>
                                        {regionList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>{errors?.zone_id}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1" className="fs-16 fw-400">Pincode</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="pincode"
                                    value={inputValues.pincode}
                                    onChange={handleInputChange}
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.pincode)}
                                    helperText={errors.pincode}
                                    className="popup-input"

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" className="fs-16 fw-400">Address</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="address"
                                    value={inputValues.address}
                                    onChange={handleInputChange}
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.address)}
                                    helperText={errors.address}
                                    className="popup-input"

                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1" className="fs-16 fw-400">State</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={inputValues.state_id }
                                        name="state_id"
                                        onChange={handleInputChange}
                                        sx={{ marginTop: "10px" }}
                                        className="popup-select"

                                    >
                                        <MenuItem value="">Select State</MenuItem>
                                        {stateList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error>{errors?.state_id}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant="body1" className="fs-16 fw-400">City</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        value={inputValues?.city_id }
                                        onChange={handleInputChange}
                                        name="city_id"
                                        sx={{ marginTop: "10px" }}
                                        className="popup-select"

                                    >
                                        <MenuItem value="">Select City</MenuItem>
                                        {cityList?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                        ))}

                                    </Select>
                                    <FormHelperText error>{errors?.city_id}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" className="fs-16 fw-400">Phone</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="phone"
                                    value={inputValues?.phone}
                                    onChange={handleInputChange}
                                    sx={{ marginTop: "10px" }}
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone}
                                    className="popup-input"

                                />
                            </Grid>
                            <Divider className="w-100" sx={{ marginBlock: "20px" }} />
                            <Grid item xs={12} className="pt-0">
                                <Typography variant="h4" className="fs-17 fw-600 mb-15px">Shipping Address</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={isSameAddress} onChange={handleCheckboxChange} />
                                        }
                                        label="Shipping Address is same as billing address"
                                    />
                                </FormGroup>
                            </Grid>

                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Box
                            sx={{ justifyContent: "center !important", gap: "10px" }}
                            component={"div"}
                            width={"100%"}
                            marginBlockStart={0.5}
                            className={classes.buttonContainer}
                        >
                            <Button sx={{width:"200px"}} className="Cancel-btn" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button sx={{width:"200px"}} className="black-btn" onClick={handleSubmit}>
                                Continue
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Modal>
        </>
    );
};

export default BillingAddress;

