import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
  password: yup.string().matches().required("Password is required"),
})
  .required();

export const addApplicantSchema = yup.object({
  first_name: yup.string().required("Please provide First Name"),
  last_name: yup.string().required("Please provide Last Name"),
  email: yup.string().email("Invalid email address").required("Please provide Email"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city: yup.string().required("Please provide City"),
  pincode: yup.string().required("Please provide Pincode"),
  zone: yup.string().required("Please provide Zone"),
  state: yup.string().required("Please provide State"),
}).required();

export const addUserRoleSchema = yup.object({
  usertype_name: yup.string().required("Please provide User Type"),
  name: yup.string().required("Please provide Role"),
}).required();

export const layoutDrawingSchema = yup.object({
  // beam_height: yup
  //   .string()
  //   .matches(/^\d{1,7}(\.\d{1,2})?$/, "Beam Height must be a number")
  //   .test('maxDigits', 'Beam Height cannot exceed 10 digits in total', function (value) {
  //     if (!value) return true;
  //     const numericValue = value.replace('.', '');
  //     return numericValue.length <= 10;
  //   })
  //   .required("Please provide beam height"),

  // ceiling_height: yup
  //   .string()
  //   .matches(/^\d{1,7}(\.\d{1,2})?$/, "Ceiling Height must be a number")
  //   .test('maxDigits', 'Ceiling Height cannot exceed 10 digits in total', function (value) {
  //     if (!value) return true;
  //     const numericValue = value.replace('.', '');
  //     return numericValue.length <= 10;
  //   })
  //   .required("Please provide ceiling height"),

  // building_height: yup
  //   .string()
  //   .matches(/^\d{1,7}(\.\d{1,2})?$/, "Building Height must be a number")
  //   .test('maxDigits', 'Building Height cannot exceed 10 digits in total', function (value) {
  //     if (!value) return true;
  //     const numericValue = value.replace('.', '');
  //     return numericValue.length <= 10;
  //   })
  //   .required("Please provide building Height"),

  // distance_from_road: yup
  //   .string()
  //   .matches(/^\d{1,7}(\.\d{1,2})?$/, "Distance from road must be a number")
  //   .test('maxDigits', 'Distance from road cannot exceed 10 digits in total', function (value) {
  //     if (!value) return true;
  //     const numericValue = value.replace('.', '');
  //     return numericValue.length <= 10;
  //   })
  //   .required("Please provide distance from road"),

  beam_height: yup
  .string()
  .matches(/^\d+(\.\d{1,2})?$/, "Beam Height must be a valid number with up to 2 decimal places")
  .test('maxDigits', 'Beam Height cannot exceed 10 digits in total', function (value) {
    if (!value) return true;
    const numericValue = value.replace('.', ''); // Remove the decimal for digit count
    return numericValue.length <= 10;
  })
  .required("Please provide beam height"),

ceiling_height: yup
  .string()
  .matches(/^\d+(\.\d{1,2})?$/, "Ceiling Height must be a valid number with up to 2 decimal places")
  .test('maxDigits', 'Ceiling Height cannot exceed 10 digits in total', function (value) {
    if (!value) return true;
    const numericValue = value.replace('.', '');
    return numericValue.length <= 10;
  })
  .required("Please provide ceiling height"),

building_height: yup
  .string()
  .matches(/^\d+(\.\d{1,2})?$/, "Building Height must be a valid number with up to 2 decimal places")
  .test('maxDigits', 'Building Height cannot exceed 10 digits in total', function (value) {
    if (!value) return true;
    const numericValue = value.replace('.', '');
    return numericValue.length <= 10;
  })
  .required("Please provide building height"),

distance_from_road: yup
  .string()
  .matches(/^\d+(\.\d{1,2})?$/, "Distance from road must be a valid number with up to 2 decimal places")
  .test('maxDigits', 'Distance from road cannot exceed 10 digits in total', function (value) {
    if (!value) return true;
    const numericValue = value.replace('.', '');
    return numericValue.length <= 10;
  })
  .required("Please provide distance from road"),

    back_side: yup.lazy((value) => {
      if (value === null || value === '') {
        return yup.string().nullable();
      } else {
        return yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed')
          .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
            return value === value?.trim();
          })
          .max(30, "Back side cannot exceed 30 digits");
      }
    }),

    right_side: yup.lazy((value) => {
      if (value === null || value === '') {
        return yup.string().nullable();
      } else {
        return yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed')
          .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
            return value === value?.trim();
          })
          .max(30, "Right side cannot exceed 30 digits");
      }
    }),
   
    left_side: yup.lazy((value) => {
      if (value === null || value === '') {
        return yup.string().nullable();
      } else {
        return yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed')
          .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
            return value === value?.trim();
          })
          .max(30, "Left side cannot exceed 30 digits");
      }
    }),

    front_side: yup.lazy((value) => {
      if (value === null || value === '') {
        return yup.string().nullable();
      } else {
        return yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed')
          .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
            return value === value?.trim();
          })
          .max(30, "Front side cannot exceed 30 digits");
      }
    }),


  // right_side: yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
  //   return value === value?.trim();
  // }).max(30, "Right side cannot exceed 30 digits"),

  // left_side: yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
  //   return value === value?.trim();
  // }).max(30, "Left side cannot exceed 30 digits"),

  // front_side: yup.string().matches(/^[0-9\s./]+$/, 'Only (0-9), no special characters allowed').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
  //   return value === value?.trim();
  // }).max(30, "Front side cannot exceed 30 digits"),

  cadFile: yup.mixed().test('cadFile', "CAD file is required", function (value) {
    return value && this.options.context?.addLayoutDrawing?.cadFile?.length > 0;
  }),
  pdfFile: yup.mixed().test('pdfFile', "PDF file is required", function (value) {
    return value && this.options.context?.addLayoutDrawing?.pdfFile?.length > 0;
  }),

  interImage: yup.array().of(yup.mixed().required("Please upload an interior image"))
    .max(4, "You can upload a maximum of 4 images.")
    .test('interior_images', "Please upload an interior image", function (value) {
      return value?.length > 0 || this.options.context?.inputDrawing?.layout_interior?.length > 0 || this.options.context?.interiorFileUrl?.length > 0;
    }),

  exteriorImage: yup.array()
    .of(yup.mixed().required("Please upload an exterior image"))
    .max(4, "You can upload a maximum of 4 exterior images.")
    .test('exterior_images', "Please upload an exterior image", function (value) {
      return value?.length > 0 || this.options.context?.inputDrawing?.layout_exterior?.length > 0 || this.options.context?.exteriorFileUrl?.length > 0;
    })

}).required();

export const cadUploadSchema = yup.object({
  cadFile: yup.mixed()
    .required("CAD file is required")
    .test("fileType", "Only DWG files are allowed", (value) => {
      // Check if value is an array and contains files
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] && value[i].type !== "application/dwg" && !value[i].name.endsWith('.dwg')) {
            return false;
          }
        }
        return true;
      }
      return false;
    })
    .test("fileSize", "Each file should be less than 10MB", (value) => {
      // Check if value is an array and contains files
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] && value[i].size > 10 * 1024 * 1024) {
            return false;
          }
        }
        return true;
      }
      return false;
    }),
}).required();

export const pdfUploadSchema = yup.object({
  pdfFile: yup.mixed()
    .required("PDF file is required")
    .test("fileType", "Only PDF files are allowed", (value) => {
      // Check if value is an array and contains files
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] && value[i].type !== "application/pdf") {
            return false;
          }
        }
        return true;
      }
      return false;
    })
    .test("fileSize", "Each file should be less than 10MB", (value) => {
      // Check if value is an array and contains files
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i] && value[i].size > 10 * 1024 * 1024) {
            return false;
          }
        }
        return true;
      }
      return false;
    }),
}).required();

export const billingAddressSchema = yup.object({
  name: yup.string().required("Please provide name"),
  city_id: yup.string().required("Please provide city"),
  pincode: yup.string().required("Please provide pincode"),
  address: yup.string().required("Please provide address"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
  phone: yup.string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Please provide Phone"),
}).required();


