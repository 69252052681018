import React, { useEffect, useState } from 'react';
import { Button, Divider, Box, Modal, Card, CardHeader, Stack, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, TableFooter } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { handleDownload, handleDownloadBaseUrl } from './downloadFile';
import pdf from '../../assets/img/pdf.png';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import ModalCss from "../../styles/modal.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import { ApproveQuotationApi } from '../../store/viewOrder/approveQuotation';
import { useDispatch } from 'react-redux';
import { showToast } from '../../toast/toast';
import { RejectQuotationApi } from '../../store/viewOrder/rejectQuotation';
import { useNavigate } from 'react-router-dom';
import { selectOutletApi } from '../../store/viewOrder/selectOutlet';

const useStyles = makeStyles((theme) => ({
    // Your styles here
}));

export default function ViewQuotation({ open, handleClose, viewOrder }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [approved, setApproved] = useState()
    const [quotations, setQuotations] = useState(viewOrder?.order_quotations || []);

    function handleApprove(id) {
        dispatch(ApproveQuotationApi({ quotation_id: id }))
            .then((res) => {
                showToast(res.payload.message, 1);
              
                setQuotations((prevQuotations) =>
                    prevQuotations.map((quotation) =>
                        quotation.id === id ? { ...quotation, dealer_approval: true } : quotation
                    )
                );
                 
            })
            .catch((error) => {
                console.error(error);
                showToast("An error occurred. Please try again.", 0);
            });
    }

    function handleReject(id) {
        dispatch(RejectQuotationApi({ quotation_id: id }))
            .then((res) => {
                showToast(res.payload.message, 1);
                setQuotations((prevQuotations) =>
                    prevQuotations.map((quotation) =>
                        quotation.id === id ? { ...quotation, dealer_approval: false } : quotation
                    )
                );
            
            })
            .catch((error) => {
                console.error(error);
                showToast("An error occurred. Please try again.", 0);
            });
    }

    useEffect(() => {
        if (open) {
            setQuotations(viewOrder?.order_quotations || []);
        }
    }, [open, viewOrder]);
    

    // function handleApprove(id) {
    //     dispatch(ApproveQuotationApi({ quotation_id: id }))
    //         .then((res) => {
    //             setApproved(true)
    //             showToast(res.payload.message, 1);
    //             handleClose()
            
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             showToast("An error occurred. Please try again.", 0);
    //         });
    // }
    

    // function handleReject(id) {
    //     dispatch(RejectQuotationApi({quotation_id:id}))
    //     .then((res)=>{
    //         setApproved(false)
    //         showToast(res.payload.message, 1);
    //         handleClose()
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //         showToast("An error occurred. Please try again.", 0);
    //     });
    // }

//     const quotations = viewOrder?.order_quotations || [];
// console.log(quotations,"quotations");
    useEffect(()=>{
          dispatch(selectOutletApi())
    },[])

    return (
        <Modal open={open} onClose={handleClose} className={ModalCss.customModal}>
            <Card className={`modal ${ModalCss.customModal_md}`}>
                <CardHeader sx={{ textAlign: "center" }} title="View Quotation" />
                <Divider sx={{ width: '100%', mt: 1, mb: 2 }} />
                <Box
                    component="div"
                    sx={{
                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
                        <IconButton onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        justifyContent="center"
                        direction="column"
                        gap={2}
                        alignItems="center"
                        fontSize={"18px"}
                        boxSizing={"border-box"}
                        component={"div"}
                    >
                        {quotations.length === 0 ? (
                            <Typography variant="h6" color="textSecondary" sx={{ marginTop: 2 }}>
                                There is no quotation.
                            </Typography>
                        ) : (
                            <TableContainer component={Paper} sx={{ maxWidth: "100%", marginTop: 2 }}>
                                <Table>
                                    <TableHead sx={{display:"block"}}>
                                        <TableRow sx={{display: "table !important",
                                                width: "100%",
                                                tableLayout: "fixed"}}>
                                            <TableCell align='center' >File Type</TableCell>
                                            <TableCell align='center'>View or Download</TableCell>
                                            <TableCell align='left'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{maxHeight:"300px",overflowY:"scroll",display:"block"}}>
                                        {quotations.map((item, index) => (
                                            <TableRow key={index} sx={{display: "table !important",
                                                width: "100%",
                                                tableLayout: "fixed"}}>
                                                <TableCell align='center' >
                                                    <img width={30} height={30} src={pdf} style={{ marginTop: "10px", marginBottom: "10px" }} alt="PDF File" />
                                                </TableCell>
                                                <TableCell align='center'  sx={{padding:"5px !important"}}>
                                                <IconButton onClick={() => handleDownload(new Date() + 'Quotation', item.quotation)}>
                                                <DownloadForOfflineRoundedIcon
                                            className="my-auto"
                                         
                                        />
                                                    </IconButton>
                                                
                                                    <IconButton 
                                                    onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${item.quotation}`)}
                                                    //onClick={() => window.open(`${item.quotation}`)}
                                                    >
                                                        <FullscreenIcon />
                                                    </IconButton>
                                                 
                                                </TableCell>
                                                <TableCell sx={{padding:"5px !important"}}>
                                                    <Stack direction='row' spacing={1}>
                                                        {item.dealer_approval==true ? <Button
                                                        variant="contained"
                                                            disabled
                                                            className={'btn black-btn'}
                                                        >
                                                            Approved
                                                        </Button>:<Button
                                                        variant="contained"
                                                            onClick={() => handleApprove(item.id)}
                                                            className={'btn black-btn'}
                                                        >
                                                            Approve
                                                        </Button>
                                                        }
                                                        
                                                    </Stack>
                                       
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        
                                    </TableBody>
                                    <TableFooter>
                                    <TableRow >
                                                <TableCell colSpan={3} sx={{background:"#ffffff !important",paddingBlock:"10px !important"}}>

                                                    <Box className="text-start">
                                                        <Typography variant='h4' className='fs-10' sx={{marginBottom:"5px"}}><span variant='h4' className='fs-10'>If you Approve : </span>Please click “View Invoice & Bill Receipt” on view order page and upload payment proof by clicking “Upload Payment Receipt”</Typography>
                                                        {/* <Typography variant='h4' className='fs-10'><span variant='h4' className='fs-10'>If You Reject : </span>Sender of Quotation will receive the notification on the same and request for resending quotation.</Typography> */}
                                                    </Box>
                                                </TableCell>

                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        )}
                    </Stack>
                </Box>
            </Card>
        </Modal>
    );
}
