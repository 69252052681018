import { Box } from "@mui/material";
import { formatDateString } from "./dateFormater";

export const getLastElement = (arr) => {
    if (arr.length === 0) {
        return null; // Return null if the array is empty
      }
      return arr[arr.length - 1]; 
};

export const getDealerApproval = (arr) => {
  const hasDealerApproved = arr.some(obj => obj.dealer_approval === true);
  return hasDealerApproved
};



export const createHtmlByArrayElement = (arr) => {
    if (arr.length === 0) {
      return null; // Return null if the array is empty
    }
    const newD = arr[arr.length-1];
    return <>
      <tr>
        <td className='w-50'>
          <Box className="w-100 pt-16 pb-16">

            <Box className="d-flex w-100">
              <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
                Transportation Number

              </Box>
              <Box className="w-50 fw-400">
                : {newD?.transportation_number ? newD?.transportation_number : "N/A"}
              </Box>
            </Box>
          </Box>
        </td>
        <td className='w-50'>
          <Box className="w-100 pt-16 pb-16">

            <Box className="d-flex w-100">
              <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
                Date
              </Box>
              <Box className="w-50 no-border fw-400">
                : {newD?.date ? formatDateString(newD?.date) : "N/A"}
              </Box>
            </Box>
          </Box>
        </td>
      </tr>
      <tr>
        <td className='w-50'>
          <Box className="w-100 pt-16 pb-16">

            <Box className="d-flex w-100">
              <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
              Delivery Person Name

              </Box>
              <Box className="w-50 fw-400">
                : {newD?.delivery_person_name ? newD?.delivery_person_name : "N/A"}
              </Box>
            </Box>
          </Box>
        </td>
        <td className='w-50'>
          <Box className="w-100 pt-16 pb-16">

            <Box className="d-flex w-100">
              <Box className="w-50 fw-600" sx={{fontFamily:"sans-serif !important"}}>
              Delivery Person Contact
              </Box>
              <Box className="w-50 no-border fw-400">
                : {newD?.delivery_contact_number ?newD?.delivery_contact_number : "N/A"}
              </Box>
            </Box>
          </Box>
        </td>
      </tr>
    </>
};