import { useState, useEffect } from 'react';
import { Alert, Stack } from '@mui/material';

const useToast = () => {
  const [toast, setToast] = useState({ message: '', type: '', open: false });

  const showToast = (message, type) => {
    setToast({ message, type, open: true });

    // Auto-close after 3 seconds
    setTimeout(() => {
      setToast({ ...toast, open: false });
    }, 3000);
  };

  // The toast JSX to be used in the component
  const Toast = () => (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {toast.open && (
        <>
          {toast.type === 1 && <Alert severity="success">{toast.message}</Alert>}
          {toast.type === 2 && <Alert severity="error">{toast.message}</Alert>}
        </>
      )}
    </Stack>
  );

  return { showToast, Toast };
};

export default useToast;
