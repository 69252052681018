import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { showToast } from "../../toast/toast";
import { sendFeedback } from "../../store/layout/getFeedback";
const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px",
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const RequestForRevision = ({ open, handleClose, id }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState("");
    const formId = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data[0]);

    const handleChange = (event) => {
        setFeedback(event.target.value);
      };
    const sendFeedbackSubmit = async() => {
        if(feedback === ""){
            showToast("Please enter revision request",2);
            return;
        }
        const formData = new FormData();
        formData.append('form_id', id);
        formData.append('user_type', "Dealer");
        formData.append('feedback', feedback);
        formData.append('feedback_on', "Schematic Layout");
        formData.append('added_for', "KIA");
        
        await dispatch(sendFeedback(formData))
          .then((data) => {
            showToast(data?.payload?.message, 1);
            handleClose()
            })
    
          .catch((error) => {
            console.error("Error editing email template:", error);
          });
      };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                className={ModalCss?.customModal}
            >
                <Card className={`modal ${ModalCss?.customModal_md}`}>
                        <CardHeader
                            sx={{textAlign: "center"}}
                            action={
                            <IconButton onClick={handleClose}>
                                <HighlightOffIcon />
                            </IconButton>
                            }
                            title="Add your feedback"
                        />
                        <CardContent className="d-flex">
                            <TextareaAutosize style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white mx-auto" minRows={6}
                                placeholder={"Enter your request"}
                                value={feedback}
                                onChange={handleChange} />
                        </CardContent>
                        <CardActions disableSpacing>
                            <Box        component={"div"}
                                width={"100%"}
                                marginBlockStart={0.5}
                                display={"flex"}
                                justifyContent={"end"}
                                alignItems={"center"}
                                gap={5}>
                                
                                <Button
                                        variant="text"
                                        size="small"
                                        sx={{
                                            color: "rgba(167, 177, 187, 1)",
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            textDecoration: "underline",
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                </Button>
                                <Button
                                        variant="contained"
                                        onClick={()=>sendFeedbackSubmit()}
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            lineHeight: 1.8,
                                            boxShadow: "none",
                                            backgroundColor: "#000 !important",
                                            p: {
                                                lg: "15px 46px",
                                                md: "13px 35px",
                                                sm: "10px 30px",
                                                xs: "10px 30px",
                                            },
                                        }}
                                        className={classes.buttonsStyle}
                                    >
                                        Send
                                </Button>
                            </Box>
                        </CardActions>
                </Card>
            </Modal>
        </>
    );
};

export default RequestForRevision;
