import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import Body from "../../components/Body";
import { useLocation, useNavigate } from "react-router-dom";
import { productListApi } from '../../store/orders/productList';
import { useDispatch, useSelector } from 'react-redux';
import AddMoreMaterial from '../reusable/AddMoreMaterial';
import { showToast } from '../../toast/toast';
import { addToCartApi, resetAddToCart } from '../../store/orders/addToCart';
import box from '../../assets/svg/box.svg';
import { updateCartApi } from '../../store/orders/updateCart';
import { resetOrderStatus } from '../../store/orders/checkOrderStatus';
import { filterCartApi } from '../../store/orders/filterCart';

function ProductList() {
    const dispatch = useDispatch();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const [open, setOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState("recived_from_dealor");
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const result = useSelector((state) => state?.productListReducer?.result);
    const address = useSelector((state) => state?.addAddressReducer?.result);
    const { order_exists: checkOrderStatus, cart: cart } = useSelector((state) => state?.checkOrderStatusReducer?.result);
    const outlet_id = useSelector(state => state?.outletListReducer?.result[0]?.id);
    const cart_id = useSelector((state) => state?.addToCartReducer?.kinUserData?.cart_id);
    const data = useSelector((state) => state?.filterCartReducer?.result.items);
    const navigate = useNavigate();
    const location = useLocation();
    const { itemId } = location.state || {};
    const clusterId= localStorage.getItem("selectedClusterForOrder")

    useEffect(() => {
        if (itemId) {
            dispatch(productListApi({ category_id: itemId }));
        }
    }, [dispatch, itemId]);

    const handleCheckboxChange = (id) => {
        setSelectedRows((prevSelected) => {
            const updatedSelectedRows = [...prevSelected];
            if (updatedSelectedRows.includes(id)) {
                const index = updatedSelectedRows.indexOf(id);
                updatedSelectedRows.splice(index, 1);
            } else {
                updatedSelectedRows.push(id);
            }
            return updatedSelectedRows;
        });
    };

    const placeOrder = async () => {
        const items = selectedRows.map(rowId => ({
            product: rowId,
            quantity: 1
        }));
        const data = {
            "outlet": clusterId,
            "address": address?.address_id,
            "items": items
        };

        try {
            await dispatch(addToCartApi(data));
            showToast("Order placed successfully");

            navigate("/order/place-Order");
        } catch (error) {
            console.error("Error placing order:", error);
        }
    };


    const handleAddToCart = async () => {
        if (checkOrderStatus === false && cart.id) {
            const updateQuantity = async () => {
                const existingItems = data?.map((item) => ({
                    product: item.product,
                    quantity: item.quantity
                }));

                const itemsToUpdate = selectedRows.map(rowId => ({
                    product: rowId,
                    quantity: 1
                }));

                const updatedItems = [...existingItems, ...itemsToUpdate];
                const requestData = {
                    id: cart_id || cart?.id,
                    items: updatedItems
                };

                try {
                    await dispatch(updateCartApi(requestData));
                    showToast("Cart updated successfully");
                    navigate("/order/place-Order");
                } catch (error) {
                    console.error("Error updating cart quantity:", error);
                }
            };

            await updateQuantity();
        } else {
            await placeOrder();
        }
    };

    useEffect(() => {
        dispatch(filterCartApi({ cart_id: cart_id ? cart_id : cart?.id }));
    }, [dispatch]);

    useEffect(() => {
        const filtered = result.filter(product => product.vendor_price !== "0");
        setFilteredProducts(filtered);
    }, [result]);

    const filterResults = result.filter((x, index, self) =>
        index === self.findIndex((y) => y.supplier_company_name === x.supplier_company_name)
    );

    const handleFilter = (supplier) => {
        setSelectedButton(supplier);
        if (supplier === "All") {
            const filtered = result.filter(product => product.vendor_price !== "0");
            setFilteredProducts(filtered);
            //setFilteredProducts(result);
        } else {
            const filtered = result.filter(product => product.vendor_price !== "0");
            setFilteredProducts(filtered.filter((x) => x.supplier_company_name === supplier));
        }
    };

    return (
        <>
            <Body>
                <Stack
                    direction="row"
                    spacing={10}
                    sx={{ my: 2 }}
                    justifyContent="space-between"
                    alignItems={"center"}
                >
                    <Stack direction="row" alignItems="center">
                        <img src={box} alt="Meter" />
                        <Typography
                            color={"#05141F"}
                            fontSize={"17px"}
                            fontWeight={400}
                            marginInlineStart={"10px"}
                            fontFamily={"'KiaSignatureRegular' !important"}
                        >
                            Product list
                        </Typography>
                    </Stack>
                </Stack>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBlock: "30px 30px !important" }}>
                            {/* <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                                <Button
                                    className={selectedButton === 'recived_from_dealor' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                            
                                >
                                    All
                                </Button>


                                {filterResults.map((x) => (
                                    <Button
                                        className={selectedButton === 'send_to_dealor' ? 'layout-blue-btn' : 'layout-lightblue-btn'} 
                                        onClick={handleFilter}
                                    >
                                        {x.supplier_company_name}
                                    </Button>
                                ))}
                            </Box> */}
                              <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                                <Button
                                    className={selectedButton === 'All' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                    onClick={() => handleFilter("All")}
                                >
                                    All
                                </Button>
                                {filterResults.map((x) => (
                                    <Button
                                        key={x.supplier_company_name}
                                        className={selectedButton === x.supplier_company_name ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                        onClick={() => handleFilter(x.supplier_company_name)}
                                    >
                                        {x.supplier_company_name}
                                    </Button>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                            <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                                <Table aria-label="customized table" sx={{ borderCollapse: "collapse" }}>
                                    <TableHead>
                                        <TableRow>
                                        <StyledTableCell  sx={{ background: "#05141F !important" }}></StyledTableCell>
                                            <StyledTableCell  sx={{ background: "#05141F !important" }}>Category</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Supplier Name</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Product Description</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Snap</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Supplier Specification</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">In Stock</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Price</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Unit</StyledTableCell>
                                            <StyledTableCell sx={{ background: "#05141F !important" }} align="center">Estimated Date by</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredProducts?.map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.id)}
                                                        onChange={() => handleCheckboxChange(row.id)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    {row?.category_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>{row?.supplier_company_name}</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    {row?.product_description}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                {row?.snap ? 
                                                    <img width={39} height={36} src={`${process.env.REACT_APP_BASE_URL}${row?.snap}`} />
                                                    : 'No Image'}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    {row?.kia_specification}
                                                </StyledTableCell>

                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}><Box className="available">Available</Box></StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    <Box className="d-flex">
                                                        <span>₹&nbsp;</span> <span>{row?.vendor_price}</span>
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>
                                                    {row?.unit_of_measurement}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" sx={{ border: "1px solid #6E93B0 !important" }}>-</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} className="flex-wrap justify-content-between">
                            <Button className="select-btn my-auto" sx={{ height: "40px" }} onClick={() => navigate(-1)}>Back</Button>
                            <Box className="gap-10 flex-wrap justify-content-center">
                                {/* <Button className="white-btn" onClick={() => handleOpen()}>Add More material</Button> */}
                                <Button className="black-btn" disabled={selectedRows.length === 0} onClick={() => handleAddToCart()}>Add to cart</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Body>
            <AddMoreMaterial open={open} handleClose={handleClose}
                result={result}
                selectedItems={selectedRows}
                //material={filteredMaterials}
                handleCheckboxChange={handleCheckboxChange}
            />
        </>
    );
}

export default ProductList;
