import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Grid, Typography,
  Stack,
  IconButton,
  Modal,
  Card,
  CardHeader,
  Box,
  CardContent,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
  Divider,
  CardActions,
  Tooltip,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import OrderSuccessfull from './OrderSuccessfull';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { diapatchOrder } from '../../store/orders/dispatchOrder';
import { useDispatch, useSelector } from 'react-redux';
import { requireCategoryApi } from '../../store/orders/getAllCategory';
import { useNavigate } from 'react-router-dom';
import { resetAddToCart } from '../../store/orders/addToCart';
import { resetOrderStatus } from '../../store/orders/checkOrderStatus';
import ModalCss from "../../styles/modal.module.scss";

const RequiredMaterial = ({ open, handleClose, purpose }) => {
  const [itemStatus, setItemStatus] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart_id = useSelector((state) => state?.addToCartReducer?.kinUserData?.cart_id);
  const { order_exists: checkOrderStatus, cart: cart } = useSelector((state) => state?.checkOrderStatusReducer?.result);
  const categoryId = useSelector((state) => state?.filterCartReducer?.result.items);
  const remainingCategory = useSelector((state) => state?.requireCategoryReducer?.result);

  useEffect(() => {
    if (remainingCategory) {
      const initialItemStatus = remainingCategory.reduce((acc, category) => ({ ...acc, [category.category]: null }), {});
      setItemStatus(initialItemStatus);
    }
  }, [remainingCategory]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const dispatchOrders = () => {
    const data = {
      cart: cart_id || cart?.id,
      purpose: purpose,
    };
    handleClose();
      dispatch(diapatchOrder(data))
      .then((response) => {
        if (response.payload.status === 'success') {
          dispatch(resetAddToCart());
          dispatch(resetOrderStatus());
          handleOpenModal();
        } else {
          console.error("Order dispatch failed:", response);
        }
      })
      .catch((error) => {
        console.error("Failed to dispatch order:", error);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleButtonClick = (item, status) => {
    setItemStatus(prevStatus => ({
      ...prevStatus,
      [item]: prevStatus[item] === status ? null : status,
    }));
  };

  const handleSelectAllNotRequired = () => {
    setItemStatus(prevStatus => {
      const allNotRequired = Object.values(prevStatus).every(status => status === 'Not Required');
      const updatedStatus = remainingCategory.reduce((acc, category) => {
        acc[category.category] = allNotRequired ? null : 'Not Required';
        return acc;
      }, {});
      return { ...prevStatus, ...updatedStatus };
    });
  };


  const [allNotRequired, setAllNotRequired] = useState(false);
  useEffect(() => {
    setAllNotRequired(Object.values(itemStatus).every(status => status === 'Not Required'));
  },[itemStatus])
  

  useEffect(() => {
    if (categoryId) {
      const items = categoryId?.map((item) => {
        return item.category_id;
      });
      
      const uniqueCategoryIds = [...new Set(items)];
      dispatch(requireCategoryApi({ category_ids: uniqueCategoryIds }));
    }
  }, [categoryId, dispatch]);

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss.customModal}>
        <Card className={`modal popup ${ModalCss?.customModal_md}`}>
          <CardHeader
            className="popup-header-rev pb-0"
            title={
              <>
                <Typography variant='h4' className='fs-20 fw-600 position-relative '>
                  You have not ordered for these categories, please confirm once and then proceed further to place the order.            
      
                </Typography>
    
              </>
            }
          />
            <CardContent className='pt-0' sx={{overflow:"hidden !important"}}>
            <Box className="text-end">
              <Tooltip title="Select All Not Required">
                <FormControlLabel control={<Checkbox checked={allNotRequired=== true} />} label="Not Required (all) " labelPlacement="start" onClick={handleSelectAllNotRequired}/>
              </Tooltip>
            </Box>
            <Box>
              <Stack alignItems="flex-end" position="absolute" right={5} top={5}>
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                <TableContainer style={{borderTop:"3px solid #EBF6FF",borderRight:"1.5px solid #EBF6FF",borderBottom:"3px solid #EBF6FF",borderLeft:"1.5px solid #EBF6FF"}} sx={{ maxHeight: 350,height:"80%" ,borderRadius:'9px'}}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                        {remainingCategory && remainingCategory.length > 0 && (
                          remainingCategory.map((category, index) => (
                            <TableRow key={index}>
                              <TableCell className="cstm-tablecell1">{category.category}</TableCell>
                              <TableCell className="cstm-tablecell1">
                                <Box className="gap-20 d-flex justify-content-end">
                                  <Button
                                    sx={{ fontSize: ".9rem !important" }}
                                    className={`back-btn ${itemStatus[category.category] === 'Required' ? 'selected' : ''}`}
                                    onClick={() => navigate("/order/material-selection")}
                                  >
                                    Required
                                  </Button>
                                  <Button
                                    className={`back-btn ${itemStatus[category.category] === 'Not Required' ? 'not-required-selected' : ''}`}
                                    sx={{ fontSize: ".9rem !important",minWidth:"max-content !important" }}
                                    onClick={() => handleButtonClick(category.category, 'Not Required')}
                                  >
                                    Not Required
                                  </Button>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </CardContent>

          <CardActions className='justify-center'>
            <Button sx={{ width: '70%', maxWidth: "320px" }} onClick={dispatchOrders} variant="contained" color="primary" disabled={!allNotRequired}>
              PROCEED TO ORDER
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <OrderSuccessfull open={openModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default RequiredMaterial;

