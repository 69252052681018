import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";

// First API
export const layoutCountApi = createAsyncThunk(
    'count/layout-design-counts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/layout/get_layout_design_counts/`, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") }
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

// Second API
export const orderCountApi = createAsyncThunk(
    'order/order-count',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/order/order_count/`, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") }
            });
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

// Slice
const countSlice = createSlice({
    name: 'layout',
    initialState: {
        layoutCountApi: { result: [], loading: false, error: null, message: "" },
        orderCountApi: { result: [], loading: false, error: null, message: "" }
    },
    extraReducers: (builder) => {
        // Handle inputDrawingApi
        builder.addCase(layoutCountApi.pending, (state) => {
            state.layoutCountApi.loading = true;
        });
        builder.addCase(layoutCountApi.fulfilled, (state, action) => {
            state.layoutCountApi.loading = false;
            state.layoutCountApi.result = action.payload?.result ?? action.payload ?? [];
            state.layoutCountApi.message = action.payload?.message ?? "";
        });
        builder.addCase(layoutCountApi.rejected, (state, action) => {
            state.layoutCountApi.loading = false;
            state.layoutCountApi.error = action?.payload?.error;
            state.layoutCountApi.message = action?.payload?.message;
        });

        // Handle secondApi
        builder.addCase(orderCountApi.pending, (state) => {
            state.orderCountApi.loading = true;
        });
        builder.addCase(orderCountApi.fulfilled, (state, action) => {
            state.orderCountApi.loading = false;
            state.orderCountApi.result = action.payload?.result ?? action.payload ?? [];
            state.orderCountApi.message = action.payload?.message ?? "";
        });
        builder.addCase(orderCountApi.rejected, (state, action) => {
            state.orderCountApi.loading = false;
            state.orderCountApi.error = action?.payload?.error;
            state.orderCountApi.message = action?.payload?.message;
        });
    },
});

// Export reducer
export const countReducer = countSlice.reducer;
